<template>
  <div id="app" class="text-sm">
    <debug-banner v-if="showDebugBanner" :vue-env="vueEnv" />
    <router-view />
    <FreshdeskWidget />
    <TalkativeWidget v-if="hasTalkative" />
  </div>
</template>

<script>
import DebugBanner from '@/components/shared/DebugBanner.vue';
import FreshdeskWidget from '@/components/shared/FreshdeskWidget.vue';
import TalkativeWidget from '@/components/shared/TalkativeWidget.vue';

export default {
  components: {
    DebugBanner,
    FreshdeskWidget,
    TalkativeWidget,
  },
  data() {
    return {
      vueEnv: process.env.VUE_APP_ENV,
    };
  },
  computed: {
    hasTalkative() {
      return this.touch_portal_user && window.touch.urlIs('endurance') && this.$route.meta.portalRoute;
    },
    showDebugBanner() {
      return (this.vueEnv?.toLowerCase() !== 'prod') & (this.vueEnv?.toLowerCase() !== 'fitshow');
    },
  },
  watch: {
    '$store.state.auth.loggedIn': {
      async handler(to) {
        if (to) {
          try {
            const response = await window.touch.authGetCompanyWebSettings()
            if (response.data.companyWebSettings.some(x => x.key === 'tagId')) {
              const index = response.data.companyWebSettings.findIndex(x => x.key === 'tagId')
              window.gtag('config', 'G-' + response.data.companyWebSettings[index].value);
            }
          } catch (error) {
            this.$store.state.auth.loggedIn = false
          }
        }
      },
      immediate: true,
    },
    style: {
      async handler() {
        var existingFavicons = document.querySelectorAll("link[rel*='icon']");
        existingFavicons.forEach(function (link) {
          link.remove();
        });
        var link = document.createElement('link');
        link.type = 'image/png';
        link.rel = 'icon';
        if (this.touch_portal_installation && this.$store.state.auth.usingBrandedModeToken) {
          link.href = this.style.customer_logo || '/favicon.ico';
        } else {
          link.href = this.style.fabricator_logo || '/favicon.ico';
        }
        document.head.appendChild(link);
      },
      immediate: true,
    },
  },
  created() {
    const tabKey = 'tabOpen';
    if (sessionStorage.getItem(tabKey) && this.branded_mode_user) {
      sessionStorage.removeItem(tabKey);
      window.location.replace('/launch/marshdemo');
    } else {
      sessionStorage.setItem(tabKey, 'true');
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem(tabKey);
    });
  },
  mounted() {
    this.initialiseStoreData();
    this.$store.state.auth.login_url = ['staging', 'prod'].includes(this.vueEnv?.toLowerCase()) ? '/login' : '/login-admin'

    if (this.inIframe()) {
      const sendHeight = () => {
        window.parent.postMessage({ type: 'resize', height: document.documentElement.scrollHeight }, '*')
      }
      window.addEventListener('resize', sendHeight)
    }
  },
  methods: {
    initialiseStoreData() {
      this.$store.dispatch('style/refresh');
      this.$store.dispatch('feature/refreshPermissions');
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  },
};
</script>

<style src="./assets/css/styles.css"></style>
