import { store } from '../../store';

export default async (to, from, next) => {
  store.state.user.freshdeskWidgetEnabled = false;
  const isLoginValid = await store.dispatch(
    'auth/validateLogin',
    window.VUE_APP_INSTALLATION_TYPE === 'business' ? 'staff' : 'customer',
  );
  if (isLoginValid && to.matched.some((record) => record.meta.isLogin === true)) {
    next('/');
  } else {
    next();
  }
};
