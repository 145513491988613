<template>
  <div>
    <label class="mt-2 flex items-center gap-2" :class="{ 'pointer-events-none opacit-50': demo_mode }">
      <div class="relative cursor-pointer" @click.stop="enabledFlag = !enabledFlag">
        <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
        <div class="switch-toggle" :class="{ _active: enabledFlag }"></div>
      </div>
      <span>Show on Branded Mode</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ProductMode',
  props: ['enabled'],
  computed: {
    enabledFlag: {
      get() {
        return this.enabled;
      },
      set(mode) {
        this.$emit('update', mode);
      },
    },
  },
};
</script>
