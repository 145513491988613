<template>
  <div v-if="expiringInXDays(branded_mode.brandedFabricatorSupplyUntil, 7)">
    <div class="relative md:mt-5 rounded-lg overflow-hidden text-center mt-5 xs:mt-0 cursor-pointer">
      <router-link to="/touch-store">
        <img class="rounded-lg w-full" src="/images/branded-mode-expiring.jpg" />
        <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2 bottom-0 w-full p-10">
          <div class="flex rounded-lg bg-gradient-to-r from-red-600 to-pink-400 py-3 px-5 items-center text-white cursor-pointer">
            <div class="mr-5 bg-white rounded-lg p-1 border-solid border-2 border-white w-8 h-8 box-content">
              <i
                class="text-2xl fa-duotone fa-unlock-keyhole text-yellow-500"
                :style="{ 'line-height': '2rem' }"
              ></i>
            </div>
            <div class="text-left">
              <div>
                <span class="text-lg">{{ `${tense} ${formatHumanDate(branded_mode.brandedFabricatorSupplyUntil)}` }}</span>
              </div>
              <div>
                <span class="text-sm">Buy Branded Mode</span>
              </div>
            </div>
            <div class="ml-auto">
              <i class="text-lg fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
  <div v-else-if="branded_mode_enabled">
    <a
      :href="store_url + 'branded-mode/deployment'"
      target="_blank"
      class="block relative md:mt-5 rounded-lg overflow-hidden text-center mt-5 xs:mt-0 cursor-pointer"
    >
      <img class="rounded-lg w-full" src="/images/brandedmode2b.jpg" />
      <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2 bottom-0 w-full p-10">
        <div
          class="flex rounded-lg bg-gradient-to-r from-blue-600 to-purple-400 py-3 px-5 items-center text-white cursor-pointer"
        >
          <div
            class="mr-5 bg-white rounded-lg p-1 border-solid border-2 border-white w-8 h-8 box-content"
          >
            <i
              class="text-xl fa-solid fa-lightbulb-on text-yellow-500"
              :style="{ 'line-height': '2rem' }"
            ></i>
          </div>
          <div class="text-left">
            <div>
              <span class="text-lg">Branded Mode Tips</span>
            </div>
            <div>
              <span class="text-sm">4 Tips on generating enquiries</span>
            </div>
          </div>
          <div class="ml-auto">
            <i class="text-lg fa fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </a>
  </div>
  <div v-else>
    <div
      class="relative md:mt-5 rounded-lg overflow-hidden text-center mt-5 xs:mt-0 cursor-pointer"
    >
      <router-link to="/touch-store">
        <img class="rounded-lg w-full" src="/images/brandedmodeb.jpg" />
        <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2 bottom-0 w-full p-10">
          <div
            class="flex rounded-lg bg-gradient-to-r from-blue-600 to-purple-400 py-3 px-5 items-center text-white cursor-pointer"
          >
            <div
              class="mr-5 bg-white rounded-lg p-1 border-solid border-2 border-white w-8 h-8 box-content"
            >
              <i
                class="text-2xl fa-duotone fa-unlock-keyhole text-yellow-500"
                :style="{ 'line-height': '2rem' }"
              ></i>
            </div>
            <div class="text-left">
              <div>
                <span class="text-lg">Try Branded Mode</span>
              </div>
              <div>
                <span class="text-sm">Activate free features</span>
              </div>
            </div>
            <div class="ml-auto">
              <i class="text-lg fa fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>

import dateHelper from '@/mixins/dateHelper';

export default {
  mixins: [dateHelper],
  computed: {
    branded_mode() {
      return this.$store.state.user.customer.eCommerceIntegration
    },
    tense() {
      return new Date(this.branded_mode.brandedFabricatorSupplyUntil) < new Date() ? 'Expired' : 'Expiring'
    }
  }
}

</script>
