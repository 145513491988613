import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import AuthModule from './auth-module';
import BasketModule from './basket-module';
import BrandedModule from './branded-module';
import CommonModule from './common-module';
import DesignerModule from './designer-module';
import FeatureModule from './feature-module';
import InfoPopupModule from './info-popup-module';
import KpiModule from './kpi-module';
import MarketingModule from './marketing-module';
import NewsModule from './news-module';
import SandboxModule from './sandbox-module';
import SocialFeedsModule from './social-feeds-module';
import StockPartsModule from './stock-parts-module';
import StyleModule from './style-module';
import TouchModule from './touch-module';
import UserModule from './user-module';
import VisualiserModule from './visualiser-module';
import KnowledgeBaseModule from './knowledge-base';
import filterPersistedData from './filter-persisted-data';

const initialState = {
  auth: { ...AuthModule.state },
  basket: { ...BasketModule.state },
  branded: { ...BrandedModule.state },
  common: { ...CommonModule.state },
  designer: { ...DesignerModule.state },
  feature: { ...FeatureModule.state },
  infoPopup: { ...InfoPopupModule.state },
  kpi: { ...KpiModule.state },
  marketing: { ...MarketingModule.state },
  news: { ...NewsModule.state },
  sandbox: { ...SandboxModule.state },
  socialFeeds: { ...SocialFeedsModule.state },
  stockParts: { ...StockPartsModule.state },
  style: { ...StyleModule.state },
  touch: { ...TouchModule.state },
  user: { ...UserModule.state },
  visualiser: { ...VisualiserModule.state },
  knowledgeBase: { ...KnowledgeBaseModule.state },
};

const ignoreList = ['basket.basket', 'touch.pageProductStoredDesignsCache'];

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {
    reset(state, persistedArray = []) {
      Object.keys(localStorage).forEach((key) => {
        if (/^public.|^staff.|^customer./.test(key)) {
          localStorage.removeItem(key);
        }
      });

      for (let i = 0; i < persistedArray.length; i += 1) {
        const persistedModule = persistedArray[i].split('.')[0];
        const persistedState = persistedArray[i].split('.')[1];
        if (persistedState) {
          initialState[persistedModule][persistedState] = state[persistedModule][persistedState];
        } else {
          initialState[persistedModule] = state[persistedModule];
        }
      }

      Object.keys(initialState).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
    },
  },
  actions: {},
  modules: {
    auth: AuthModule,
    basket: BasketModule,
    branded: BrandedModule,
    designer: DesignerModule,
    common: CommonModule,
    kpi: KpiModule,
    marketing: MarketingModule,
    news: NewsModule,
    sandbox: SandboxModule,
    stockParts: StockPartsModule,
    style: StyleModule,
    touch: TouchModule,
    user: UserModule,
    infoPopup: InfoPopupModule,
    visualiser: VisualiserModule,
    socialFeeds: SocialFeedsModule,
    feature: FeatureModule,
    knowledgeBase: KnowledgeBaseModule,
  },
});

const addPersistedState = (store, isSession) => {
  const persistedState = createPersistedState({
    key:  isSession ? undefined : process.env.VUE_APP_INSTALLATION_TYPE,
    paths: filterPersistedData(initialState, ignoreList),
    storage: isSession ? window.sessionStorage : window.localStorage,
  });
  persistedState(store);
};

export { store, addPersistedState };
