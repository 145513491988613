<template>
  <touch-layout :visualiser_place_page="true">
    <div class="visualiser-outer-wrapper">
      <div
        v-show="info_tooltip_open"
        class="absolute top-0 left-0 right-0 bottom-0 bg-black_80 z-40"
      />
      <div
        id="visualiser_start_info_popup"
        class="info-popup-wrapper"
        :class="{ _products_area_open: products_area_open }"
      >
        <div v-show="info_tooltip_open" class="info-popup-message-wrapper">
          <div class="info-popup-close" @click.stop="info_tooltip_open = false">
            <i class="fal fa-times" />
          </div>
          <div class="info-popup-message mb-2">
            To start click the image of your window or door below to add it to the canvas. Drag the
            corners of the image to re-size. Click the main house image to deactivate the size
            controls.
          </div>
          <div class="info-popup-button">
            <div @click.stop="info_tooltip_open = false">Start</div>
          </div>
        </div>
      </div>
      <div
        class="toggle-product-image-area-button z-40"
        :class="{ _open: products_area_open }"
        @click="toggleProductImagesArea()"
      >
        <div class="bg-white border-t border-l border-r rounded-t-lg flex cursor-pointer">
          <div class="flex flex-col justify-around font-medium text-base">
            <div class="flex">
              <div v-if="!products_area_open" class="p-4 border-r">
                <i class="fal fa-chevron-up" />
              </div>
              <div v-if="products_area_open" class="px-2 pt-1">
                <i class="fal fa-chevron-down" />
              </div>
              <div v-if="!products_area_open" class="p-4">Your New Products</div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!uploaded_images_area_open"
        class="fixed left-0 bottom-0 z-40 ml-1"
        @click="toggleUploadedImagesArea()"
      >
        <div class="bg-white border-t border-l border-r rounded-t-lg flex cursor-pointer">
          <div class="flex flex-col justify-around font-medium text-base">
            <div class="flex">
              <div class="p-4 border-r">
                <i class="fal fa-chevron-up" />
              </div>
              <div class="p-4">Your Home Images</div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="selectedImage && visualiserReady"
        ref="containerScrollable"
        class="bg-gray-200 overflow-auto mx-auto relative h-full"
      >
        <api-img
          ref="imageOnScreen"
          :src="selectedImage.url"
          style="max-width: initial;"
          class="h-full"
          @load="setImageContainerDimensions"
        />
        <div
          id="image-holder"
          ref="images"
          class="absolute top-0 left-0 bottom-0 right-0"
          @click="$store.commit('visualiser/deactivateAllImages')"
        >
          <jquery-ui-draggable
            v-for="(image, key) in placedImages"
            :key="selectedImage.id + '::' + image.position.randomKey"
            class="flex"
            :transform="image.position.transform"
            :transform-origin="image.position['transform-origin']"
            :x="image.position.x"
            :y="image.position.y"
            :x1="image.position.x1"
            :y1="image.position.y1"
            :x2="image.position.x2"
            :y2="image.position.y2"
            :x3="image.position.x3"
            :y3="image.position.y3"
            :x4="image.position.x4"
            :y4="image.position.y4"
            :active="image.position.active"
            :index="key"
            @update="update(key, $event)"
          >
            <show-svg
              class="w-full h-full"             
              :preserve-aspect-ratio="false"
              :branded-mode="true"
              :show-issues="false"
              :thumbnail-mode="true"
              :svg="image.product.image"
            />
          </jquery-ui-draggable>
        </div>
      </div>
      <div
        class="visualiser-uploaded-images-area z-40"
        :class="{ _open: uploaded_images_area_open, _products_area_open: products_area_open }"
      >
        <div v-if="uploaded_images_area_open" class="ml-1" @click="toggleUploadedImagesArea()">
          <div class="bg-white border-t border-l border-r rounded-t-lg cursor-pointer inline-block">
            <div class="flex flex-col justify-around font-medium text-base">
              <div class="flex">
                <div v-if="uploaded_images_area_open" class="px-2 pt-1">
                  <i class="fal fa-chevron-down" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex p-3 overflow-x-scroll scrolling-touch bg-white">
          <place-image-tile v-for="image in images" :key="image.key" :image="image" />
          <router-link :to="{ name: branded_mode_user ? 'visualiser' : 'visualiser-portal' }" class="visualiser-place-image-tile">
            <div class="truncate pb-2 w-full text-center">Edit / Upload Images</div>
            <div
              class="bg-gray-200 p-3 flex-grow flex flex-col justify-center text-center hover:bg-gray-300"
              style="width: 150px"
            >
              <i class="fal fa-upload text-3xl" />
            </div>
          </router-link>
        </div>
      </div>
      <div class="visualiser-products-area z-40" :class="{ _open: products_area_open }">
        <div class="p-6">
          <div class="md:flex justify-between mb-3">
            <div class="flex flex-col justify-around">
              <strong class="block text-lg">Products</strong>
            </div>
            <div class="flex mt-3 md:mt-0">
              <label
                class="bg-white rounded-full rounded-r-none border py-1 px-6 cursor-pointer"
                :class="{ 'bg-green-600 text-white border-green-600': view === 'inside' }"
              >
                Inside
                <input
                  v-model="view"
                  type="radio"
                  name="inside_or_outside"
                  value="inside"
                  class="hidden"
                />
              </label>
              <label
                class="bg-white rounded-full rounded-l-none border py-1 px-6 cursor-pointer"
                :class="{ 'bg-green-600 text-white border-green-600': view === 'outside' }"
              >
                Outside
                <input
                  v-model="view"
                  type="radio"
                  name="inside_or_outside"
                  value="outside"
                  class="hidden"
                />
              </label>
            </div>
          </div>
          <a
            v-if="productIsSelected"
            class="btn-danger sm:w-full my-3"
            @click.stop="deleteProduct()"
          >
            Delete Selected Product
          </a>
          <div class="visualiser-products-list">
            <template v-for="product in products">
              <place-product-tile
                v-if="!product.omitFromVisualiser"
                :key="product.itemKey"
                class="visualiser-place-product-tile"
                :product="product"
                :view="view"
                @place="placeProduct(product)"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import PlaceImageTile from '@/components/branded/visualiser/PlaceImageTile.vue';
import PlaceProductTile from '@/components/branded/visualiser/PlaceProductTile.vue';
import JqueryUiDraggable from '@/components/branded/visualiser/JqueryUiDraggable.vue';

export default {
  components: {
    'jquery-ui-draggable': JqueryUiDraggable,
    'place-image-tile': PlaceImageTile,
    'place-product-tile': PlaceProductTile,
  },
  data() {
    return {
      show_modal: false,
      uploaded_images_area_open: false,
      products_area_open: true,
      info_tooltip_open: true,
    };
  },
  computed: {
    ...mapGetters({
      selectedImage: 'visualiser/selectedImage',
      images: 'visualiser/images',
      placedImages: 'visualiser/placedImages',
      productIsSelected: 'visualiser/productIsSelected',
      products: 'basket/fenestrationItems',
    }),
    visualiserReady() {
      return this.products.length > 0;
    },
    view: {
      get() {
        this.$store.dispatch('visualiser/refreshProductImages');

        if (!this.$store.getters['visualiser/selectedVisualisation']) {
          return 'outside';
        }

        return this.$store.getters['visualiser/selectedVisualisation'].insideView
          ? 'inside'
          : 'outside';
      },
      set(view) {
        this.$store.commit('visualiser/setView', view === 'inside');
        this.$store.dispatch('visualiser/refreshProductImages');
      },
    },
  },
  async mounted() {
    await this.$store.dispatch('basket/refresh');
    this.$store.dispatch('visualiser/loadImages');
    this.$store.dispatch('visualiser/refreshProductImages', true);
    window.addEventListener('resize', this.setImageContainerDimensions)
  },
  methods: {
    setImageContainerDimensions() {
      if (this.$refs.containerScrollable) {
        let container = document.getElementById('image-holder')
        container.style.width = `${this.$refs.containerScrollable.scrollWidth}px`;
        container.style.height = `${this.$refs.containerScrollable.scrollHeight}px`;
      }
    },
    update(id, payload) {
      this.$store.commit('visualiser/updatePosition', {
        id,
        payload,
        imageWidth: this.$refs.imageOnScreen.$el.width,
        imageHeight: this.$refs.imageOnScreen.$el.height,
      });
    },
    deleteProduct() {
      this.$store.dispatch('visualiser/deleteActiveProduct');
    },
    placeProduct(product) {
      this.uploaded_images_area_open = false;
      this.$store.dispatch('visualiser/addProduct', {
        product,
        imageWidth: this.$refs.imageOnScreen.$el.width,
        imageHeight: this.$refs.imageOnScreen.$el.height,
      });
    },
    toggleUploadedImagesArea() {
      this.uploaded_images_area_open = !this.uploaded_images_area_open;
    },
    toggleProductImagesArea() {
      this.products_area_open = !this.products_area_open;
    },
  },
};
</script>
