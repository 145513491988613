export default {
  namespaced: true,
  state: {
    articles: [
      {
        duration: undefined,
        title: 'Portal Setup Checklist',
        id: '5',
        imageURL: 'Portal Setup Checklist.png',
        url: process.env.VUE_APP_STORE_PREFIX + 'portal/how-to-set-up'
      },
      {
        duration: undefined,
        title: 'How to Buy Branded Mode',
        id: '1',
        imageURL: 'How to Buy Branded Mode.png',
        url: process.env.VUE_APP_STORE_PREFIX + 'branded-mode/how-to-purchase'
      },
      {
        duration: undefined,
        title: 'Branded Mode Setup Guide',
        id: '2',
        imageURL: 'Branded Mode Setup Guide.png',
        url:process.env.VUE_APP_STORE_PREFIX + 'branded-mode/how-to-set-up'
      },
      {
        duration: undefined,
        title: 'Deploying Branded Mode',
        id: '3',
        imageURL: 'Deploying Branded Mode.png',
        url: process.env.VUE_APP_STORE_PREFIX + 'branded-mode/deployment'
      },
      {
        duration: undefined,
        title: 'Increase Sales with Branded Mode',
        id: '4',
        imageURL: 'Increase Sales with Branded Mode.png',
        url: process.env.VUE_APP_STORE_PREFIX + 'branded-mode/benefits'
      },
    ],
    hiddenArticlesIDs: [],
  },
  mutations: {
    addHiddenArticlesIDs(state, Id) {
      state.hiddenArticlesIDs.push(Id);
    },
    removeHiddenArticlesIDs(state, Id) {
      const index = state.hiddenArticlesIDs.indexOf(Id);
      state.hiddenArticlesIDs.splice(index, 1);
    },
  },
  actions: {
    async generateLinkWeb2Print (context, { jwt_token }) {
      const parsedUrl = process.env.NODE_ENV === 'development' 
        ? new URL(process.env.VUE_APP_ENDPOINT_URL)
        : new URL(window.location)
      const prefix = parsedUrl.hostname.split('.')[0];
      const { url } = await window.touch.generateLinkWeb2Print({
        jwt_token,
        prefix
      })
      const response = window.open(url, '_blank');
      if (!response) {
        window.location.href = url
      }
    }
  }
};
