<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['My Branded Mode Account', '/setup'], ['Products']]"
      />
    </template>
    <template #header_page_title> Products </template>
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <ProductsList v-if="product_overrides" :product_overrides="product_overrides" setup="Branded Mode">
        <template #product_mode="{ product_mode_data }">
          <ProductMode
            class="mt-2"
            :enabled="product_mode_data.customerEnabledRetail"
            @update="updatecustomerEnabledRetailFlag(product_mode_data, $event)"
          >
          </ProductMode>
        </template>
      </ProductsList>
    </div>
  </touch-layout>
</template>

<script>
import ProductsList from '@/components/shared/setup/products/ProductsList.vue';
import ProductMode from '@/components/portal/setup/products/ProductMode.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Breadcrumbs,
    ProductsList,
    ProductMode
  },
  data() {
    return {
      product_overrides: undefined,
    };
  },
  async mounted() {
    this.product_overrides = await this.customerGetAllProductsWithOverrides();
    this.product_overrides.sort((a, b) =>
      a.productName.localeCompare(b.productName, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );
  },
  methods: {
    ...mapActions({
      customerGetAllProductsWithOverrides: 'user/customerGetAllProductsWithOverrides',
    }),
    updatecustomerEnabledRetailFlag(product, mode) {
      if (mode) {
        this.$set(product, 'customerEnabled', true);
      }
      this.$set(product, 'customerEnabledRetail', mode);
    },
  },
};
</script>
