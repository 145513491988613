import Vue from 'vue';
import VueRouter from 'vue-router';
import ErrorPage from '@/views/Public/ErrorPage.vue';

import * as Sentry from '@sentry/browser';
import { store, addPersistedState } from '../store';

import publicRoutes from './routes/public';
import brandedRoutes from './routes/branded';
import portalRoutes from './routes/portal';
import businessRoutes from './routes/business';

import portalMode from './middleware/portal';
import brandedMode from './middleware/branded';
import businessMode from './middleware/business';
import publicMode from './middleware/public';

Vue.use(VueRouter);

const routes = [
  ...publicRoutes,
  ...brandedRoutes,
  ...portalRoutes,
  ...businessRoutes,
  // the below route is a catch all for unregistered routes, and will show a 404 page for them
  // it needs to be declared after ALL OTHER ROUTES as is done here.
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage,
    props: () => ({ errorType: window.enum.errorType['404'] }),
    beforeEnter: (to, from, next) => {
      Sentry.captureEvent({
        message: `404: Page Not Found: ${to?.fullPath}`,
      });
      next();
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/**
 * Middlware - Require Auth - to proceed you must be logged in - if not go to login page.
 */

router.beforeEach(async (to, from, next) => {
  const installation = window.VUE_APP_INSTALLATION_TYPE;
  if (to.path === '/login' || to.path === '/login-admin') {
    addPersistedState(store, false)
    next();
  } else if (to.matched.some((record) => record.meta.brandedModeRoute === true)) {
    addPersistedState(store, true)
    brandedMode(to, from, next);
  } else if (to.matched.some((record) => record.meta.publicRoute === true)) {
    if(to.name === 'Launch Branded Designer') {
      addPersistedState(store, true)
    } else {
      addPersistedState(store, false)
    }
    publicMode(to, from, next);
  } else if (installation === 'portal') {
    addPersistedState(store, false)
    portalMode(to, from, next);
  } else if (installation === 'business') {
    addPersistedState(store, false)
    businessMode(to, from, next);
  } else {
    // Shoud never be hit?
    addPersistedState(store, false)
    next();
  }
});

router.afterEach((to) => {
  store.commit('style/setPageName', to.name);
});

export default router;
