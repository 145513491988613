var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"bg-white overflow-x-auto pl-3 md:pl-10"},[_c('div',{staticClass:"flex bg-white gap-10 border-t-4 border-white"},[_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'details',
          'border-white': _vm.active_section !== 'details',
        },attrs:{"to":"/my-account/details"}},[_vm._v(" My Details ")]),_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'staff',
          'border-white': _vm.active_section !== 'staff',
        },attrs:{"to":"/my-account/staff"}},[_vm._v(" Staff & Licenses ")]),_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'addresses',
          'border-white': _vm.active_section !== 'addresses',
        },attrs:{"to":_vm.touch_business_user ? `/customer/${_vm.$route.params.id}/addresses` : '/my-account/addresses'}},[_vm._v(" Addresses & Delivery Days ")]),_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'custom-styles',
          'border-white': _vm.active_section !== 'custom-styles',
        },attrs:{"to":"/my-account/custom-styles"}},[_vm._v(" Custom Styles ")]),(!_vm.isLeadGen && _vm.$store.state.auth.superuser)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'touch-connect',
          'border-white': _vm.active_section !== 'touch-connect',
        },attrs:{"to":"/my-account/touch-connect"}},[_vm._v(" Touch Connect ")]):_vm._e(),(_vm.$store.state.auth.superuser)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'products',
          'border-white': _vm.active_section !== 'products',
        },attrs:{"to":"/my-account/products"}},[_vm._v(" Products ")]):_vm._e(),(_vm.$store.state.user.customer.customer.useCustomerOnlyGlass)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'my-prices',
          'border-white': _vm.active_section !== 'my-prices',
        },attrs:{"to":"/my-account/my-prices"}},[_vm._v(" My Prices ")]):_vm._e(),(_vm.$store.state.knowledgeBase.hiddenArticlesIDs.length > 0)?_c('router-link',{staticClass:"flex-shrink-0 cursor-pointer border-b-4 py-2 hover:border-black",class:{
          'border-black': _vm.active_section === 'hidden-articles',
          'border-white': _vm.active_section !== 'hidden-articles',
        },attrs:{"to":"/my-account/hidden-articles"}},[_vm._v(" Hidden Articles ")]):_vm._e(),_c('div',{staticClass:"pr-5"})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }