<template>
  <div>
    <div class="m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium" v-text="'Connect Codes'"></div>
    </div>
    <div class="bg-white border m-3 xs:m-10 p-3">
      <table v-if="codes" class="table-lined xs:table-unstacked w-full">
        <thead>
          <tr>
            <th>Type</th>
            <th>Code</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="codes.codeComponents && false">
            <td>Components</td>
            <td>
              {{ codes.codeComponents }}
            </td>
            <td class="text-right">
              <button
                class="w-full sm:w-auto"
                :class="[
                  copiedCode === enums.connectType.COMPONENTS ? 'btn-action' : 'btn-primary',
                ]"
                @click.prevent="copyURL(enums.connectType.COMPONENTS, codes.codeComponents)"
              >
                Copy Code
                <transition name="fade-in" mode="out-in">
                  <i
                    v-if="copiedCode === enums.connectType.COMPONENTS"
                    class="fal fa-check ml-3"
                  ></i>
                  <i v-else class="fal fa-copy ml-3"></i>
                </transition>
              </button>
            </td>
          </tr>
          <tr v-if="codes.codeProducts">
            <td>Products</td>
            <td>
              {{ codes.codeProducts }}
            </td>
            <td class="text-right">
              <button
                class="w-full sm:w-auto"
                :class="[copiedCode === enums.connectType.PRODUCTS ? 'btn-action' : 'btn-primary']"
                @click.prevent="copyURL(enums.connectType.PRODUCTS, codes.codeProducts)"
              >
                Copy Code
                <transition name="fade-in" mode="out-in">
                  <i v-if="copiedCode === enums.connectType.PRODUCTS" class="fal fa-check ml-3"></i>
                  <i v-else class="fal fa-copy ml-3"></i>
                </transition>
              </button>
            </td>
          </tr>
          <tr v-if="codes.codeStock">
            <td>Stock</td>
            <td>
              {{ codes.codeStock }}
            </td>
            <td class="text-right">
              <button
                class="w-full sm:w-auto"
                :class="[copiedCode === enums.connectType.STOCK ? 'btn-action' : 'btn-primary']"
                @click.prevent="copyURL(enums.connectType.STOCK, codes.codeStock)"
              >
                Copy Code
                <transition name="fade-in" mode="out-in">
                  <i v-if="copiedCode === enums.connectType.STOCK" class="fal fa-check ml-3"></i>
                  <i v-else class="fal fa-copy ml-3"></i>
                </transition>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <span>No codes available.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codes: undefined,
      copiedCode: undefined,
    };
  },
  async mounted() {
    this.codes = await this.getConnectCodes();
  },
  methods: {
    async getConnectCodes() {
      return window.touch.getConnectCodes();
    },
    async copyURL(type, code) {
      this.copiedCode = type;

      try {
        await navigator.clipboard.writeText(code);
      } catch ($e) {
        this.alertBox().fire('Copy Failed');
        this.copiedCode = undefined;
        return;
      }

      setTimeout(() => {
        this.copiedCode = undefined;
      }, 2000);
    },
  },
};
</script>

<style scoped></style>
